import React from "react";
import { AuthReducerAction } from "./types";
import { AuthActionType } from "./constants";
import config from "../../config";
import MeService from "../../common/services/meService";
import AuthApiService from "./services/authApiService";


export const signIn = async (dispatch: React.Dispatch<AuthReducerAction>, email: string, password: string ) => {
  console.log("Entering signIn()");

  try {
    dispatch({ type: AuthActionType.RequestSignIn });
    const res = await AuthApiService.requestSignIn(email, password);
    if (res && res.ok) {
      return await res.json();
    } else {
      throw new Error("Sign-in attempt failed");
    }
  } catch (err) {
    console.error("err: " + err);
    dispatch({ type: AuthActionType.SignInError });
    throw new Error("Sign-in attempt failed");
  }
}

export const updateAuthState = async (dispatch: React.Dispatch<AuthReducerAction>) => {
  try {
    const res = await MeService.getMe();
    if (res && res.ok) {
      const data = await res.json();
      dispatch({ type: AuthActionType.UpdateAuthState, payload: data });
    } else {
      window.location.href = config.AUTH_APP_URL;
      throw new Error("User must sign-in again")
    }
  } catch (err) {
    console.error("Error: " + err);
    //throw new Error("User must sign-in again");
    //window.location.href = config.AUTH_APP_URL;
  }
}

export const signOut = async (dispatch: React.Dispatch<AuthReducerAction>) => {
  console.log("Entering signOut()");

  try {
    dispatch({ type: AuthActionType.RequestSignOut });
    const res = await AuthApiService.requestSignOut();
    if (res.ok) {
      dispatch({ type: AuthActionType.SignOutSuccess });
      window.location.href = config.AUTH_APP_URL;
    } else {
      throw new Error("Sign-out attempt failed");
    }
  } catch (err) {
    console.error("err: " + err);
    dispatch({ type: AuthActionType.SignOutError });
  }
};