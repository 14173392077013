import { apiRequest } from "../../../utils/apiService";

class AuthApiService {

  static async requestSignIn(email: string, password: string) {
    return await apiRequest("/auth/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    });
  }
  
  static async requestSignOut() {
    return await apiRequest("/auth/signout", { method: "POST" });
  }
  
  static async changePassword(newPassword: string, sessionToken: string) {
    return await apiRequest("/auth/password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        newPassword: newPassword,
        sessionToken: sessionToken
      })
    });
  }
}

export default AuthApiService;