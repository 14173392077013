import React from 'react';
import MeService from '../../common/services/meService';

const HomePage: React.FC = () => {
  const [testData, setTestData] = React.useState("");
  
  const getTestData = async () => {
    setTestData("Loading test data...");

    try {
      const res = await MeService.getMe();
      const json = await res.json();

      setTestData(JSON.stringify(json));
    } catch (error) {
      console.error(error);
      setTestData("Error");
    }
  }

  return (
    <div>
      <h2>Home</h2>
      <button onClick={getTestData}>Fetch Test Data From Server</button>

      <div style={{
        display: "flex",
        marginTop: "50px",
        padding: "4em",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
        }}>
        <h2>Test data will appear below when fetch test data button is clicked:</h2>
        <span>{testData}</span>
        
      </div>

    </div>
  );
};

export default HomePage;