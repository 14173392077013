import React, { useEffect, useRef } from "react";
import logo from "../logo.svg";
import "./App.css";
import { AuthActionType, signOut, useAuthDispatch, useAuthState } from "../stores/auth";
import { updateAuthState } from "../stores/auth/actions";

interface AppHeaderProps {
  title: string;
}

const AppHeader: React.FC<AppHeaderProps> = ({ title }) => {
  const authState = useAuthState();
  const authDispatch = useAuthDispatch();
  const [dropDownOpen, setDropDownOpen] = React.useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null); // Ref to track the dropdown element
  
  const handleSignOut = async () => {
    await signOut(authDispatch);
  }

  const toggleDropDown = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const closeDropDown = (e: MouseEvent) => {
    if (dropDownOpen && !dropDownRef.current?.contains(e.target as Node)) {
      setDropDownOpen(false);
    }
  }

  useEffect(() => {
    if (!authState.authenticated) {
      // Check if the user is authenticated
      updateAuthState(authDispatch);
    }

    return () => {};
  }, []);
  
  useEffect(() => {
    document.addEventListener("mousedown", closeDropDown);
    return () => {
      document.removeEventListener("mousedown", closeDropDown);
    };
  }, [dropDownOpen]); // Rerun only when dropDownOpen state changes

  return (
    <div className="App-header">
      
      <div className="header-left">
        <img src={logo} className="app-logo" alt="logo" />
        < span>{title}</span>
      </div>

      <div ref={dropDownRef} className="header-right">
        <button className={`dropdown-toggle ${dropDownOpen ? "active-text" : ""}`} onClick={toggleDropDown} aria-expanded={dropDownOpen}> 
          { authState.email ? (
            authState.email
          ) : ""}
          <span className={`arrow-icon ${dropDownOpen ? "up" : "down"}`}>
            {dropDownOpen ? "▲" : "▼"}
          </span>
        </button>
        {dropDownOpen && (
          <div className="dropdown-menu">
            
            <a href="#option1" className="dropdown-item">
              Option 1
            </a>
            <button className ="dropdown-item" onClick={handleSignOut}>Sign Out</button>
          </div>
        )}
      </div>
      
      
    </div>
  )
}

export default AppHeader;